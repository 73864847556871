import React, { useState, useEffect } from 'react';
import axios from 'axios';
import quickt from '../../assets/quicktransfer.png';

const Dashboard = (props) => {
  const [bankArray, setBankArray] = useState([]);
  const [selectedBank, setSelectedBank] = useState('');
  const [sender, setSender] = useState([]);
  const [receiver, setReceiver] = useState([]);
  const [block, setBlock] = useState([]);
  const [amount, setAmount] = useState('');

  useEffect(() => {
    const fetchBankDetails = async () => {
      try {
        const resp = await axios.post("https://skenderpay.xyz:8443/getBankDetails", { userId: 3 });
        setBankArray(resp.data.data);
        console.log(resp.data.data);
      } catch (error) {
        console.error('Error fetching bank details:', error);
      }
    };

    fetchBankDetails();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const senderResp = await axios.post("https://skenderpay.xyz:8443/getUserProfile", { userId: 3 });
        setSender(senderResp.data.data);

        const receiverResp = await axios.post("https://skenderpay.xyz:8443/getUserProfile", { userId: 3 });
        setReceiver(receiverResp.data.data);

        const blockResp = await axios.post("https://skenderpay.xyz:8443/getBlockDetails", { id: 3 });
        setBlock(blockResp.data.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleTransaction = async () => {
    try {
      const transactionResp = await axios.post("https://skenderpay.xyz:8443/transaction", {
        sender,
        receiver,
        block,
        amount,
        bank: selectedBank
      });
      console.log(transactionResp.data.data);
    } catch (error) {
      console.error('Error processing transaction:', error);
    }
  };

  return (
    <div className="frame9-home-v2-quicktransfer">
      <span className="frame9-home-v2-text121 HeadingH6">
        <span>Sweep to Bank</span>
      </span>
      <div className="frame9-home-v2-input">
        <div className="frame9-home-v2-selectcard">
          {bankArray.length > 0 && (
            <select 
              className="frame9-home-v2-dropdown" 
              value={selectedBank} 
              onChange={(e) => setSelectedBank(e.target.value)}
            >
              <option value="">Select Bank</option>
              {bankArray.map((bank, index) => (
                <option key={index} value={bank.bank_name}>
                  {bank.bank_name}
                </option>
              ))}
            </select>
          )}
          <div className="frame9-home-v2-amount3">
            <span className="frame9-home-v2-text125 bodymediumextrabold">
              <span>$10,431</span>
            </span>
            <img
              src="/chevrondowni194-ilxn.svg"
              alt="chevrondownI194"
              className="frame9-home-v2-chevrondown4"
            />
          </div>
        </div>
        <div className="frame9-home-v2-inputamount">
          <span className="frame9-home-v2-text127 bodysmallmedium">
            <span>Enter amount</span>
          </span>
          <div className="frame9-home-v2-input1">
            <div className="frame9-home-v2-frame8">
              <input
                type="text"
                className="frame9-home-v2-text129 HeadingH4"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <img
                src="/line1i194-opsy.svg"
                alt="Line1I194"
                className="frame9-home-v2-line1"
              />
            </div>
          </div>
        </div>
      </div>
      <button className="frame9-home-v2-button" onClick={handleTransaction}>
        <span className="frame9-home-v2-text131 bodylargeextrabold">
          <span>Send Money</span>
        </span>
      </button>
    </div>
  );
};

export default Dashboard;
