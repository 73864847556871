import React, { useState } from "react";
import module from "./style.module.css";
import useInputVal from "../../hooks/use-input-val";
import logo from "../../assets/Logo.png";
import { useNavigate } from "react-router-dom";
import middleImg from "../../assets/MiddleImg.png";
import { useDispatch } from "react-redux";
import { loginAction } from "../../store/login-slice";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  function handleRegisterClick() {
    navigate("/signup1");
  }

  const validateEmail = (input) => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (input.match(validRegex)) {
      return true;
    } else {
      return false;
    }
  };
  const validatePassword = (input) => {
    let out = false;
    var lowerCaseLetters = /[a-z]/g;
    if (input.match(lowerCaseLetters)) {
      out = true;
    } else {
      out = false;
    }

    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    if (input.match(upperCaseLetters)) {
      out = true;
    } else {
      out = false;
    }

    // Validate numbers
    var numbers = /[0-9]/g;
    if (input.match(numbers)) {
      out = true;
    } else {
      out = false;
    }

    // Validate length
    if (input.length >= 8) {
      out = true;
    } else {
      out = false;
    }
    return out;
  };
  const {
    value: enteredUsername,
    isValid: enteredUsernameIsValid,
    hasError: usernameHasError,
    valueChangeHandler: usernameChangeHandler,
    blurHandler: usernameBlurHandler,
    reset: usernameInputReset,
  } = useInputVal(validateEmail);

  const {
    value: enteredPassword,
    isValid: enteredPasswordIsValid,
    hasError: passwordHasError,
    valueChangeHandler: passwordChangeHandler,
    blurHandler: passwordBlurHandler,
    reset: passwordInputReset,
  } = useInputVal(validatePassword);

  let formIsValid = false;

  if (enteredUsernameIsValid && enteredPasswordIsValid) {
    formIsValid = true;
  }

  const onSubmitHandler = async (event) => {
    event.preventDefault();
    if (!formIsValid) {
      return;
    }
    const data = {
      email: enteredUsername,
      password: enteredPassword,
    };
    const resp = await validatedUser(data);
    console.log(resp);
    if (resp && typeof resp === "object" && resp.message === "success") {
      setError(false);
      dispatch(
        loginAction.userLogin({
          userId: resp.userId,
          firstName: resp.firstName,
        })
      );
      navigate("/dashboard");
      usernameInputReset();
      passwordInputReset();
    } else {
      setError(true);
    }
  };

  async function validatedUser(data) {
    console.log(data);
    const resp = await fetch("https://skenderpay.xyz:8443/check", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const parsedResp = await resp.json();
    console.log(resp);
    const respF = {
      message: parsedResp.message,
      userId: parsedResp.user_id,
      firstName: parsedResp.FirstName,
    };
    return respF;
  }

  const toggleForgotPassword = () => {
    navigate("/passwordreset");
  };

  const usernameClasses = usernameHasError
    ? module["inputUserName"] + " " + module["invalid"]
    : module["inputUserName"];

  const passwordClasses = passwordHasError
    ? module["inputPassword"] + " " + module["invalid"]
    : module["inputPassword"];

  return (
    <div className={module["parentContainer"]}>
      <div className={module["container"]}>
        <div className={module["innerContainer"]}>
          <div className={module["firstParent"]}>
            <div className={module["innerFirstParent"]}>
              <div className={module["subInnerParent"]}>
                <div className={module["logo"]}>
                  <img src={logo} alt="SkenderPayLogo" />
                </div>
                <div className={module["textSpan"]}>Skenderpay</div>
              </div>
              <div className={module["firstImage"]}></div>
            </div>
            <div className={module["middleImage"]}>
              <img src={middleImg} alt="Middle showcase" />
            </div>

            <div className={module["textParent"]}>
              <span className={module["text1"]}>
                {" "}
                Fast, Secure, User-Friendly Digital Payments! <br />{" "}
              </span>
              <span className={module["text2"]}>
                Exciting developments are on the horizon for the digital payment
                landscape with the imminent arrival of a cutting-edge platform
                at the intersection of AI and blockchain payments technology.{" "}
                <br />
                <span className={module["dollarText"]}></span>
                Our innovative solution promises to revolutionize transactions
                offering unparalleled security, efficiency, and transparency.{" "}
                <br />
                Stay tuned for the unveiling of SkenderPay platform the
                game-changing digital payment platform, set to redefine the way
                we engage in financial transactions.
              </span>
            </div>
            <div className={module["secondImage"]}></div>
          </div>
          <div className={module["secondParent"]}>
            <div className={module["formParent"]}>
              <div className={module["formHeading"]}>
                <h2>Login</h2>
              </div>
              <form onSubmit={onSubmitHandler}>
                <div
                  className={`${module["invalidUser"]} ${module["errorText"]}`}
                >
                  {error && (
                    <p>Either your username or password is incorrect</p>
                  )}
                </div>
                <div>
                  <label className={module["labelUserName"]} htmlFor="userName">
                    User Name
                  </label>
                  <input
                    type="text"
                    id="userName"
                    className={usernameClasses}
                    onChange={usernameChangeHandler}
                    placeholder="Enter your username"
                    onBlur={usernameBlurHandler}
                    value={enteredUsername}
                  />
                </div>
                <div>
                  <label className={module["labelPassword"]} htmlFor="password">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className={passwordClasses}
                    onChange={passwordChangeHandler}
                    placeholder="Enter your password"
                    onBlur={passwordBlurHandler}
                    value={enteredPassword}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className={module["loginButton"]}
                    disabled={!formIsValid}
                  >
                    Login
                  </button>
                </div>
                <div className={module["buttonContainer"]}>
                  <button
                    className={module["registerButton"]}
                    onClick={handleRegisterClick}
                  >
                    New User?
                  </button>
                  <button
                    className={module["forgotButton"]}
                    onClick={toggleForgotPassword}
                  >
                    Forgot Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
